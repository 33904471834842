import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IArchivoElectronico from '../../../Interfaces/IArchivoElectronico'
//import type { RootState } from '../../store'

const initialState = {
  Referencia: '',
  Anio: 0,
  Pedimento: '',
  NoCliente: 0
}

export const ArchivoElectronicoSlice = createSlice({
  name: 'ArchivoElectronico',
  initialState,
  reducers: {
    setArchivoElectronico: (
      state,
      action: PayloadAction<IArchivoElectronico>
    ) => {
      console.log('Cambio de valor: ' + JSON.stringify(action))
      state.Referencia = action.payload.Referencia
      state.Anio = action.payload.Anio
        ? action.payload.Anio
        : new Date().getFullYear()
      state.Pedimento = action.payload.PedimentoLargo
      state.NoCliente = action.payload.NoCliente
    }
  }
})

export const { setArchivoElectronico } = ArchivoElectronicoSlice.actions
//export const selectuserStatus = (state: RootState) => state.userStatus.value
export default ArchivoElectronicoSlice.reducer
