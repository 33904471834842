import React, { FC, useEffect, useState } from 'react'
// Bootstrap
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { BsCloudDownload } from 'react-icons/bs'
// Interfaces
import IArchivoElectronico from '../../../Interfaces/IArchivoElectronico'
// DTOs
import DTOgetArchivosPorReferencia from '../../../DTO/DTOgetArchivosPorReferencia'
// Redux
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
// Servicios
import AEOServices from '../../../Services/Catalogos/ArchivoElectronico.Services'
import ArchivoElectronicoDataServices from '../../../Services/Catalogos/ArchivoElectronico.Services'
// Otros
import { AgGridReact } from 'ag-grid-react'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { TargetURL } from '../../../Constants/TargetURL'

interface IProps {
  showDialog: boolean
  header: string
  closeDialog: (arg: boolean) => void
}

interface ListaArchivos {
  archivo: string
}

const URL = new TargetURL()

export const DialogAE: FC<IProps> = (props) => {
  const [curURL, setCurURL] = useState(URL.get())
  const Referencia = useSelector(
    (state: RootState) => state.ArchivoElectronico.Referencia
  )
  const Pedimento = useSelector(
    (state: RootState) => state.ArchivoElectronico.Pedimento
  )
  const NoCliente = useSelector(
    (state: RootState) => state.ArchivoElectronico.NoCliente
  )
  const Anio = useSelector((state: RootState) => state.ArchivoElectronico.Anio)
  const [filteredData, setFilteredData] = useState<Array<ListaArchivos>>()
  const [columnDefs] = useState([
    {
      field: 'archivo',
      width: 430,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: true,
      filter: true
    }
  ])
  const gridRef = React.useRef<any>(null)
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')

  useEffect(() => {
    const data: IArchivoElectronico = {
      Referencia: Referencia,
      Anio: Anio,
      PedimentoLargo: Pedimento.trim(),
      NoCliente: NoCliente,
      IdUsuario: 0,
      Archivo: 'dummy'
    }
    ArchivoElectronicoDataServices.getFileList(data)
      .then((response) => {
        const lista: ListaArchivos[] = []
        response.data.forEach((item) => {
          lista.push({ archivo: item })
        })
        setFilteredData(lista)
      })
      .catch((e: Error) => {
        console.log(e)
        return
      })
  }, [Referencia])

  const viewFileContent = (fileName: string) => {
    window.open(
      `${curURL}/ArchivoElectronico/getFileContent?Referencia=${Referencia}&PedimentoLargo=dummy&NoCliente=${NoCliente}&IdUsuario=0&Archivo=${fileName}`
    )
  }

  const getFilesByReference = () => {
    let selectedNodes = gridRef.current.api.getSelectedNodes()
    let selectedData = selectedNodes.map((node: any) => node.data)
    const Archivos = selectedData.map((item: ListaArchivos) => item.archivo)
    const data: DTOgetArchivosPorReferencia = {
      Referencia: Referencia,
      PedimentoLargo: Pedimento,
      NoCliente: NoCliente,
      IdUsuario: 0,
      Archivo: 'dummy',
      Archivos: Archivos
    }
    AEOServices.getTheseFilesByReferences(data)
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', Referencia + '.zip')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    props.closeDialog(false)
  }

  return (
    <div>
      {' '}
      <Modal
        dialogClassName="modal-50w"
        show={props.showDialog}
        onHide={() => {
          props.closeDialog(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Referencia: {Referencia}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filteredData ? (
            <div
              style={{ height: '350px', width: '450px' }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                rowData={filteredData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={50}
                ref={gridRef}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
              ></AgGridReact>
            </div>
          ) : (
            'Sin archivos que mostrar'
          )}
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col md={2}></Col>
              <Col md={4}>
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => {
                    getFilesByReference()
                  }}
                >
                  <BsCloudDownload /> Descargar
                </Button>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    props.closeDialog(false)
                  }}
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => setShowMsg(false)}
      />
    </div>
  )
}
